.table-option {
    display: flex;
    margin: 5px 2px;
    gap: 5px;
  }
  .table-option {
    white-space: nowrap;
  }
  .table-input {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    gap: 3px;
  }
  .table-unit {
    width: 15px;
    height: 15px;
    border: 1px solid lightgray;
  }
  
  blockquote{
    border-left: 2px solid #ddd;
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    color: #aaa;
    font-style: italic;
}
.slateEditorTable table, .slateEditorTable th, .slateEditorTable td {
    border: 1px solid black;
}

.slateEditorTable table{
    border-collapse: collapse;
}

.btnActive{
    opacity: 1;
}
.editor-wrapper{
    font-family:'Helvetica','Arial', sans-serif;
    border-radius: 10px;
background: #ffffff;
box-shadow:  -8px 8px 13px #ededed,
             8px -8px 13px #ffffff;
    min-height: 400px;
    height: fit-content;
}
.slateEditorTable{
    width:99%;
    border-collapse: collapse;
}

.slateEditorTable td{
    height: 50px;
    padding:0 5px;
}
.popup-wrapper{
    display: inline;
    position: relative;
}
.popup{
    position: absolute;
    left: 0;
    background-color: white;
    padding: 6px 10px;
    border: 1px solid lightgray;
    height: fit-content;
    z-index: 1000;

}
