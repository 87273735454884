
.e_step_header{
    align-items:center;
    background:#fafbfc;
    border:1px solid #bac4cc;
    border-radius:6px;
    cursor:default;
    display:flex;
    padding:7px;
    position:relative;
    width: 500px;
}
.e_step_header:hover{
    border-color:#476270;
    box-shadow:0 3px 3px #04141a08,0 8px 12px -14px #04141a14
}

.e_step_icon{
  align-items:center;
  display:flex;
  height:auto;
  min-height:32px;
  width:32px;
  justify-content: center;
  border:1px solid #bac4cc;
  border-radius:6px;
  background: #ebe9e9;
  color:#637a87
}

.e_step_title{
  vertical-align: middle;
  margin:0 12px
}

.e_steps_title{
  font-size: 14px !important;
  font-weight: 500;
  margin: 10px 0px;
  text-transform: uppercase;
}

.e_step_title_text{
  font-size: 14px !important;
  margin:0;
}

.straight-arrow {
  left: 0;
  margin-left: 24px;
  position: relative;
}

.straight-arrow_common .straight-arrow__container {
  height: 49px
}

.straight-arrow__container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.adaptive-arrow {
  align-items: center;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  width: 2px;
}

.adaptive-arrow__start {
  flex: 0 0 auto;
  width: 100%;
  /* background:#ddd */
}

.adaptive-arrow__label {
  color: #24434f;
  font-size: 12px;
  font-weight: 600;
  padding: 7px;
  pointer-events: none;
  transition: color .3s ease;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.adaptive-arrow__end {
  flex: 1 1 0;
  width: 100%;
  /* background:#ddd */
}

/* --------------===================================--------- */







.straight-arrow{
  left:0;
  margin-left:24px;
  position:relative
}
@media (max-width: 480px){
  .straight-arrow{
      margin-left:8px
  }
}
.straight-arrow_selected .adaptive-arrow__start-point:before{
  border-color:#108291
}
.straight-arrow_selected .adaptive-arrow__pointer:after{
  border-top-color:#108291
}
.straight-arrow_selected .repeat-arrow__pointer{
  border-left-color:#108291
}
.straight-arrow_selected .subtree-start-arrow__fork-icon{
  background:#108291
}
.straight-arrow_selected svg path{
  stroke:#108291
}
.recipe-action-step_with-dragged-child .straight-arrow_selected,.recipe-action-step_dragged .straight-arrow_selected{
  pointer-events:none
}
.recipe-action-step_with-dragged-child .straight-arrow_selected .adaptive-arrow__start-point:before,.recipe-action-step_dragged .straight-arrow_selected .adaptive-arrow__start-point:before{
  border-color:#6dc8ce
}
.recipe-action-step_with-dragged-child .straight-arrow_selected .adaptive-arrow__pointer:after,.recipe-action-step_dragged .straight-arrow_selected .adaptive-arrow__pointer:after{
  border-top-color:#6dc8ce
}
.recipe-action-step_with-dragged-child .straight-arrow_selected .repeat-arrow__pointer,.recipe-action-step_dragged .straight-arrow_selected .repeat-arrow__pointer{
  border-left-color:#6dc8ce
}
.recipe-action-step_with-dragged-child .straight-arrow_selected .subtree-start-arrow__fork-icon,.recipe-action-step_dragged .straight-arrow_selected .subtree-start-arrow__fork-icon{
  background:#6dc8ce
}
.recipe-action-step_with-dragged-child .straight-arrow_selected svg path,.recipe-action-step_dragged .straight-arrow_selected svg path{
  stroke:#6dc8ce
}
.straight-arrow__container{
  display:flex;
  flex-direction:column;
  position:relative
}
.straight-arrow__button-container{
  bottom:0;
  left:0;
  position:absolute;
  right:0;
  top:0;
  width:458px;
  z-index:2
}
.straight-arrow path{
  stroke:#bac4cc;
  stroke-width:1px;
  transition:stroke .3s ease;
}
.straight-arrow_start .straight-arrow__container{
  height:40px
}
.straight-arrow_start .straight-arrow__button-container{
  bottom:auto;
  height:40px;
  top:0
}
.straight-arrow_common .straight-arrow__container{
  height:40px
}
.straight-arrow_common .straight-arrow__button-container{
  bottom:0;
  height:40px;
  top:auto
}
.straight-arrow_else{
  bottom:0;
  pointer-events:none;
  position:absolute;
  right:0;
  top:25px
}
.straight-arrow_else .straight-arrow__container{
  height:100%;
  pointer-events:all
}
.straight-arrow_else .straight-arrow__button-container{
  height:30px;
  pointer-events:all;
  top:auto;
  z-index:3
}


/* ===================================== */
.recipe-step-marker{
  bottom:0;
  opacity:0;
  position:absolute;
  top:0;
  width:100%;
  z-index:3
}
.recipe-step-marker:hover{
  opacity:1;
  transition:opacity .3s ease
}
.recipe-step-marker_active{
  opacity:1
}
.recipe-step-marker_active .recipe-step-marker__actions{
  display:none
}
.recipe-step-marker__inner{
  align-items:center;
  display:flex;
  justify-content:space-between;
  position:absolute;
  top:50%;
  transform:translateY(-50%);
  width:100%
}
.recipe-step-marker__buttons{
  background:transparent;
  display:flex;
  font-size:0;
  padding:0 4px
}
.w-dnd__droppable .recipe-step-marker__buttons{
  display:none
}
.recipe-step-marker__button{
  -webkit-appearance:none;
  background:none;
  border:none;
  white-space:nowrap;
  cursor:pointer;
  padding:0 8px
}
.recipe-step-marker__button:focus:not(.focus-visible){
  outline:none
}
.recipe-step-marker__button-inner{
  border-radius:50%;
  color:#637a87;
  display:block;
  padding:8px
}
.recipe-step-marker__button:hover .recipe-step-marker__button-inner{
  background:#eef8fa;
  color:#108291
}
.recipe-step-marker__line{
  flex:1;
  height:1px;
  position:relative
}
.recipe-step-marker__line_before:before{
  content:"";
  height:8px;
  left:-3px;
  position:absolute;
  top:-4px;
  transform:rotate(45deg);
  width:8px
}
.recipe-step-marker__line_after:after{
  content:"";
  height:8px;
  position:absolute;
  right:0;
  top:-4px;
  width:1px
}
.recipe-step-marker__line,.recipe-step-marker__line:before,.recipe-step-marker__line:after{
  background:#ced5db
}
.recipe-step-marker_active .recipe-step-marker__line,.recipe-step-marker_active .recipe-step-marker__line:after,.recipe-step-marker_active .recipe-step-marker__line:before{
  background:#6dc8ce
}
.w-dnd__droppable .recipe-step-marker__line,.w-dnd__droppable .recipe-step-marker__line:after,.w-dnd__droppable .recipe-step-marker__line:before{
  background:#e67009
}  
.recipe-step-marker__overlay{
  pointer-events:none
}

/* Add Step Css */
.add-step {
  min-height: 32px;
}

.add-step__trigger {
  -webkit-appearance: none;
  background: none;
  border: none;
  padding: 0;
  white-space: nowrap;
  align-items: center;
  background: #fafbfc;
  border: 1px dashed #bac4cc;
  color: #637a87;
  cursor: pointer;
  display: flex;
  height: 32px;
  justify-content: center;
  transition: background-color .2s ease,border-color .2s ease;
  width: 32px;
  border-radius: 4px;
  margin: 2px 0 0 9px;
  overflow: hidden;
  position: absolute;
}


.add-step__container {
  overflow: hidden;
}

.add-step__header {
  font-weight: 600;
  margin-top: 8px;
}

.add-step__content {
  align-items: flex-start;
  display: flex;
}

.add-step__controls {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}

.add-step__control {
  -webkit-appearance: none;
  background: none;
  border: none;
  padding: 0;
  white-space: nowrap;
  cursor: pointer;
  margin: 8px 22px 8px 0;
  text-align: center;
  white-space: normal;
  width: 52px;
}

.add-step__icon-wrapper {
  align-items: center;
  background: #fafbfc;
  border: 1px dashed #bac4cc;
  color: #637a87;
  cursor: pointer;
  display: flex;
  height: 32px;
  justify-content: center;
  transition: background-color .2s ease,border-color .2s ease;
  width: 32px;
  border-radius: 2px;
  height: 52px;
  width: 52px;
}

.add-step__icon {
  height: 32px;
  width: 32px;

  align-items: stretch;
  display: flex;
  height: auto;
  min-height: 32px;
  width: 32px;
}



.add-step__close {
  -webkit-appearance: none;
  background: none;
  border: none;
  padding: 0;
  white-space: nowrap;
  align-items: center;
  background: #fafbfc;
  border: 1px dashed #bac4cc;
  color: #637a87;
  cursor: pointer;
  display: flex;
  height: 32px;
  justify-content: center;
  transition: background-color .2s ease,border-color .2s ease;
  width: 32px;
  border-radius: 50%;
  flex: 0 0 32px;
  margin-top: 17px;
}

.recipe-step-icon_special {
  align-items: center;
  background: #e4e8eb;
  border: 1px solid #bac4cc;
  border-radius: 2px;
  color: #476270;
  display: inline-flex;
  justify-content: center;
}

.recipe-step-icon {
  flex: 1;
}

.add-step__name {
  display: inline-block;
  font-size: 12px;
  line-height: 1.32;
  margin-top: 8px;
}

.adaptive-arrow__pointer {
  flex: 0 0 0;
  position: relative;
}

.adaptive-arrow__pointer:after {
  border-color: transparent;
  border-style: solid;
  border-top-color: #bac4cc;
  border-width: 10px 5px 0;
  bottom: 0;
  content: "";
  display: block;
  left: -5px;
  opacity: 1;
  position: absolute;
  transition: border-color .3s ease,opacity .3s ease;
}


/* Sub treeeeeeee start css */
.subtree-start-arrow{
  left:0;
  margin-left:24px;
  position:relative;
  z-index:1
}
@media (max-width: 480px){
  .subtree-start-arrow{
      margin-left:8px
  }
}
.subtree-start-arrow_selected .adaptive-arrow__start-point:before{
  border-color:#108291
}
.subtree-start-arrow_selected .adaptive-arrow__pointer:after{
  border-top-color:#108291
}
.subtree-start-arrow_selected .repeat-arrow__pointer{
  border-left-color:#108291
}
.subtree-start-arrow_selected .subtree-start-arrow__fork-icon{
  background:#108291
}
.subtree-start-arrow_selected svg path{
  stroke:#108291
}
.recipe-action-step_with-dragged-child .subtree-start-arrow_selected,.recipe-action-step_dragged .subtree-start-arrow_selected{
  pointer-events:none
}
.recipe-action-step_with-dragged-child .subtree-start-arrow_selected .adaptive-arrow__start-point:before,.recipe-action-step_dragged .subtree-start-arrow_selected .adaptive-arrow__start-point:before{
  border-color:#6dc8ce
}
.recipe-action-step_with-dragged-child .subtree-start-arrow_selected .adaptive-arrow__pointer:after,.recipe-action-step_dragged .subtree-start-arrow_selected .adaptive-arrow__pointer:after{
  border-top-color:#6dc8ce
}
.recipe-action-step_with-dragged-child .subtree-start-arrow_selected .repeat-arrow__pointer,.recipe-action-step_dragged .subtree-start-arrow_selected .repeat-arrow__pointer{
  border-left-color:#6dc8ce
}
.recipe-action-step_with-dragged-child .subtree-start-arrow_selected .subtree-start-arrow__fork-icon,.recipe-action-step_dragged .subtree-start-arrow_selected .subtree-start-arrow__fork-icon{
  background:#6dc8ce
}
.recipe-action-step_with-dragged-child .subtree-start-arrow_selected svg path,.recipe-action-step_dragged .subtree-start-arrow_selected svg path{
  stroke:#6dc8ce
}
.subtree-start-arrow__container{
  display:flex;
  flex-direction:column;
  position:relative
}
.subtree-start-arrow__button-container{
  bottom:0;
  left:0;
  position:absolute;
  right:0;
  top:0;
  width:458px;
  z-index:2
}
.subtree-start-arrow path{
  stroke:#bac4cc;
  stroke-width:1px;
  transition:stroke .3s ease;
}
.subtree-start-arrow__end{
  align-self:flex-end
}
.subtree-start-arrow__fork-icon{
  background:#bac4cc;
  height:8px;
  left:-3px;
  position:absolute;
  top:26px;
  transform:rotate(45deg);
  transition:background-color .3s ease;
  width:8px
}
.subtree-start-arrow__container{
  height:calc(60px);
  width:72px
}
@media (max-width: 480px){
  .subtree-start-arrow__container{
      width:18px
  }
}
.subtree-start-arrow .recipe-step-marker{
  left:71px
}
.subtree-start-arrow .recipe-step-marker__inner{
  bottom:4px;
  top:auto;
  transform:none
}
@media (max-width: 480px){
  .subtree-start-arrow .recipe-step-marker{
      left:17px
  }
}


.recipe-action-step__block {
  margin-left: 70px;
  position: relative;
}

.in-view-item {
  border-bottom: 1px solid transparent;
  border-top: 1px solid transparent;
}
.recipe-action-step, .recipe-action-step__arrows-container {
  position: relative;
}

.recipe-step {
  position: relative;
  width: 482px;
}

/* Sub tree end css */
.subtree-end-arrow{
  left:0;
  margin-left:24px;
  position:relative
}
@media (max-width: 480px){
  .subtree-end-arrow{
      margin-left:8px
  }
}
.subtree-end-arrow_selected .adaptive-arrow__start-point:before{
  border-color:#108291
}
.subtree-end-arrow_selected .adaptive-arrow__pointer:after{
  border-top-color:#108291
}
.subtree-end-arrow_selected .repeat-arrow__pointer{
  border-left-color:#108291
}
.subtree-end-arrow_selected .subtree-start-arrow__fork-icon{
  background:#108291
}
.subtree-end-arrow_selected svg path{
  stroke:#108291
}
.recipe-action-step_with-dragged-child .subtree-end-arrow_selected,.recipe-action-step_dragged .subtree-end-arrow_selected{
  pointer-events:none
}
.recipe-action-step_with-dragged-child .subtree-end-arrow_selected .adaptive-arrow__start-point:before,.recipe-action-step_dragged .subtree-end-arrow_selected .adaptive-arrow__start-point:before{
  border-color:#6dc8ce
}
.recipe-action-step_with-dragged-child .subtree-end-arrow_selected .adaptive-arrow__pointer:after,.recipe-action-step_dragged .subtree-end-arrow_selected .adaptive-arrow__pointer:after{
  border-top-color:#6dc8ce
}
.recipe-action-step_with-dragged-child .subtree-end-arrow_selected .repeat-arrow__pointer,.recipe-action-step_dragged .subtree-end-arrow_selected .repeat-arrow__pointer{
  border-left-color:#6dc8ce
}
.recipe-action-step_with-dragged-child .subtree-end-arrow_selected .subtree-start-arrow__fork-icon,.recipe-action-step_dragged .subtree-end-arrow_selected .subtree-start-arrow__fork-icon{
  background:#6dc8ce
}
.recipe-action-step_with-dragged-child .subtree-end-arrow_selected svg path,.recipe-action-step_dragged .subtree-end-arrow_selected svg path{
  stroke:#6dc8ce
}
.subtree-end-arrow__container{
  display:flex;
  flex-direction:column;
  position:relative
}
.subtree-end-arrow__button-container{
  bottom:0;
  left:0;
  position:absolute;
  right:0;
  top:0;
  width:458px;
  z-index:2
}
.subtree-end-arrow path{
  stroke:#bac4cc;
  stroke-width:1px;
  transition:stroke .3s ease;
  vector-effect:non-scaling-stroke
}
.subtree-end-arrow__start{
  align-self:flex-end;
  height:24px
}
.subtree-end-arrow__left{
  height:12px
}
.subtree-end-arrow__container{
  height:calc(60px);
  width:72px
}
@media (max-width: 480px){
  .subtree-end-arrow__container{
      width:18px
  }
}
.subtree-end-arrow__button-container{
  height:30px
}
.subtree-end-arrow__button-container_inner{
  top:4px
}
.subtree-end-arrow__button-container_inner .recipe-step-marker{
  left:71px
}
@media (max-width: 480px){
  .subtree-end-arrow__button-container_inner .recipe-step-marker{
      left:17px
  }
}
.subtree-end-arrow__button-container_inner .recipe-step-marker__inner{
  bottom:auto;
  top:0;
  transform:none
}
.subtree-end-arrow__button-container_outer{
  bottom:4px;
  top:auto
}
.subtree-end-arrow__button-container_outer .recipe-step-marker{
  left:1px
}
.subtree-end-arrow__button-container_outer .recipe-step-marker__inner{
  bottom:0;
  top:auto;
  transform:none
}


/* Labeled path css */
.labeled-path{
  display:flex
}
.labeled-path__svg{
  display:flex;
  width:100%
}
.labeled-path__label{
  fill:#24434f;
  font-size:12px;
  font-weight:700;
  pointer-events:none;
  transition:color .3s ease;
  -webkit-user-select:none;
  -ms-user-select:none;
  user-select:none
}


/* Repeat Arrow Css */
.repeat-arrow{
  left:0;
  margin-left:24px;
  position:relative;
  bottom:0px;
  height:calc(100% - 50px);
  pointer-events:none;
  position:absolute;
  right:0;
  top:50px
}
@media (max-width: 480px){
  .repeat-arrow{
      margin-left:8px
  }
}
.repeat-arrow_selected .adaptive-arrow__start-point:before{
  border-color:#108291
}
.repeat-arrow_selected .adaptive-arrow__pointer:after{
  border-top-color:#108291
}
.repeat-arrow_selected .repeat-arrow__pointer{
  border-left-color:#108291
}
.repeat-arrow_selected .subtree-start-arrow__fork-icon{
  background:#108291
}
.repeat-arrow_selected svg path{
  stroke:#108291
}
.recipe-action-step_with-dragged-child .repeat-arrow_selected,.recipe-action-step_dragged .repeat-arrow_selected{
  pointer-events:none
}
.recipe-action-step_with-dragged-child .repeat-arrow_selected .adaptive-arrow__start-point:before,.recipe-action-step_dragged .repeat-arrow_selected .adaptive-arrow__start-point:before{
  border-color:#6dc8ce
}
.recipe-action-step_with-dragged-child .repeat-arrow_selected .adaptive-arrow__pointer:after,.recipe-action-step_dragged .repeat-arrow_selected .adaptive-arrow__pointer:after{
  border-top-color:#6dc8ce
}
.recipe-action-step_with-dragged-child .repeat-arrow_selected .repeat-arrow__pointer,.recipe-action-step_dragged .repeat-arrow_selected .repeat-arrow__pointer{
  border-left-color:#6dc8ce
}
.recipe-action-step_with-dragged-child .repeat-arrow_selected .subtree-start-arrow__fork-icon,.recipe-action-step_dragged .repeat-arrow_selected .subtree-start-arrow__fork-icon{
  background:#6dc8ce
}
.recipe-action-step_with-dragged-child .repeat-arrow_selected svg path,.recipe-action-step_dragged .repeat-arrow_selected svg path{
  stroke:#6dc8ce
}
.repeat-arrow__container{
  display:flex;
  flex-direction:column;
  position:relative
}
.repeat-arrow__button-container{
  bottom:0;
  left:0;
  position:absolute;
  right:0;
  top:0;
  width:458px;
  z-index:2
}
.repeat-arrow path{
  stroke:#bac4cc;
  stroke-width:1px;
  transition:stroke .3s ease;
}
@media (max-width: 480px){
  .repeat-arrow{
      height:calc(100% - 40px);
      top:40px
  }
}
.repeat-arrow__start{
  bottom:7px;
  height:12px;
  position:absolute;
  right:0
}
.repeat-arrow__line{
  position:absolute
}
.repeat-arrow__line_bottom{
  bottom:0;
  height:7px;
  width:72px
}
@media (max-width: 480px){
  .repeat-arrow__line_bottom{
      width:18px
  }
}
.repeat-arrow__line_left{
  height:calc(100% - 14px);
  top:7px;
  width:2px
}
.repeat-arrow__line_top{
  height:7px;
  top:0;
  width:26px
}
@media (max-width: 480px){
  .repeat-arrow__line_top{
      width:8px
  }
}
.repeat-arrow__pointer{
  border-color:transparent;
  border-left-color:#bac4cc;
  border-style:solid;
  border-width:5px 0 5px 10px;
  display:block;
  height:0;
  left:26px;
  margin-left:-5px;
  margin-top:-4px;
  opacity:1;
  position:absolute;
  transition:border-color .3s ease;
  width:0
}
@media (max-width: 480px){
  .repeat-arrow__pointer{
      left:8px
  }
}
.repeat-arrow__container{
  height:100%;
  position:absolute;
  width:72px
}
@media (max-width: 480px){
  .repeat-arrow__container{
      width:18px
  }
}
.repeat-arrow__container:before{
  transition:background-color .3s ease
}
.repeat-arrow__button-container{
  height:19px;
  pointer-events:all;
  top:auto
}
.repeat-arrow .recipe-step-marker{
  left:70px
}


.recipe-action-step__arrows-container_foreach {
  padding-bottom: 19px;
}

.recipe-action-step, .recipe-action-step__arrows-container {
  position: relative;
}