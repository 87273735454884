.edgebutton-foreignobject {
  border-radius: 50%;
  background: transparent !important;
}

.edgebutton-foreignobject > body {
  background: transparent !important;
}

.collapsed {
  display: none;
}

.edgebutton {
  display: none;
  background: white;
  padding: 6;
  height: max-content;
  width: max-content;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}

.decision-rectangle,
.react-flow__node > .node-container {
  border: 1px solid #3f4e6b !important;
  border-radius: 3px;
}

.react-flow__node > .selected-node {
  border: 2px solid #f51b4e !important;
  border-radius: 3px;
  box-shadow: 2px 2px 11px 1px #3f4e6b;
}

.react-flow__node > .node-container-finish {
  border: 1px solid #ff5da6 !important;
  border-radius: 16px;
}

.react-flow__node > .node-container-start {
  border: 1px solid #3f4e6b !important;
  border-radius: 16px;
}

.react-flow__node-default {
  width: min-content !important;
  min-width: 180px;
}

.react-flow__node-input {
  width: min-content !important;
  min-width: 160px;
  border-radius: 16px;
}

.react-flow__node-output {
  width: min-content !important;
  min-width: 180px;
}

.react-flow__node-default,
.react-flow__node-default.selected,
.selected:hover {
  border: none !important;
  box-shadow: none !important;
}

.react-flow__node > .node-container > .node-header {
  background: #3f4e6b;
  color: white;
  padding: 5px;
  /* display: flex; */
  justify-content: flex-end;
  cursor: grab;
  display: none !important;
}

.react-flow__node > .node-container > .node-body {
  padding: 5px;
  min-width: max-content;
}

.react-flow__node > .node-container > .node-body > .node-users {
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-flow__node > .node-container > .node-body > .node-text {
  cursor: pointer;
}

/* Evaluation Project Progress */
.decision-rectangle,
.react-flow__node > .node-container-eva {
  border: 1px solid #3f4e6b !important;
  border-radius: 3px;
}

.react-flow__node > .node-container-eva-start {
  border: 1px solid #3f4e6b !important;
  border-radius: 16px;
}

.react-flow__node > .node-container-eva > .node-header {
  background: #3f4e6b;
  color: white;
  padding: 5px;
  cursor: grab;
  display: flex;
  align-items: center;
  align-content: space-between;
  justify-content: center;
}

.react-flow__node > .node-container-eva > .node-body {
  padding: 5px;
  min-width: max-content;
  min-height: 30px;
}

.react-flow__node > .node-container-eva > .node-body > .node-users {
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-flow__node > .node-container-eva > .node-header > .node-title {
  margin: 0;
  font-size: 12px;
}

.react-flow__node > .node-container-eva > .node-header > .checkedIcon {
  margin-left: 10px;
}

.react-flow__node
  > .node-container-eva
  > .node-body
  > .node-users
  [class*="MuiAvatar-root"] {
  width: 20px;
  height: 20px;
  font-size: 12px;
}

.react-flow__node > .node-container-eva > .node-body > .node-users > .node-msg {
  margin: 0;
  font-size: 12px;
}

.react-flow__node
  > .node-container-eva
  > .node-body
  > .node-users
  [class*="MuiAvatarGroup-avatar"] {
  margin: 0;
}
/* 
.react-flow__node > .react-flow__handle-top:first-child {
  display: none;
} */

.react-flow__handle {
  width: 8px;
  height: 8px;
  z-index: 20;
  background: transparent;
  background-color: transparent;
  color: transparent;
  border: none;
  bottom: 0px !important;
}

.react-flow__handle-bottom {
  /* width: 0.5px; */
  /* height: 0.5px; */
  margin-top: 5px;
}

.draggable {
  cursor: pointer;
  display: flex;
  align-items: flex-start;
}

.draggable > .img {
  margin-right: 8px;
  margin-top: 4px;
  width: 17px;
  min-width: 17px;
  height: 17px;
}