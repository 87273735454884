

.control{
  background:#f7f9fa;
  border:1px solid #ced5db;
  margin-bottom:36px;
  padding:8px 16px 8px 8px;
  position:relative
}
.control:after{
  border:1px solid #bac4cc;
  bottom:-37px;
  content:"";
  height:36px;
  left:26px;
  position:absolute;
  width:1px
}
.control:hover{
  border-color:#476270
}

.control_readonly:hover{
  border-color:#ced5db
}

.control_readonly:last-child:after{
  display:none
}

.control.cdk-drag-preview:after{
  display:none
}

.control-condition{
  display:flex;
  justify-content:space-between
}
.control-condition__operand{
  align-items:center;
  background: #dcdbba;
  border:1px solid #cbc990;;
  border-radius:2px;
  color:#555224;
  display:flex;
  flex:0 0 40px;
  font-weight:700;
  justify-content:center;
  margin-right:16px;
  text-transform:uppercase
}
.control-condition__form{
  flex:1;
  min-width:0;
  padding:16px 0
}

.control-conditions__add-section{
  padding:0 8px
}

.control-conditions__action{
  -webkit-appearance:none;
  background:none;
  border:none;
  padding:0;
  white-space:nowrap;
  align-items:center;
  background:#ffffff;
  border:1px dashed #bac4cc;
  border-radius:2px;
  color:#637a87;
  cursor:pointer;
  display:flex;
  font-weight:700;
  height:40px;
  justify-content:center;
  margin-right:20px;
  min-width:40px;
  text-transform:uppercase;
  transition: background .2s ease,border-color .2s ease
}
.control-conditions__action:focus:not(.focus-visible){
  outline:none
}
.control-conditions__action:last-child{
  margin-right:0
}
.control-conditions__action:hover{
  background:#e8e8d6;
  border-color:#cbc990;
  color:#133440
}
.control-conditions__action_close{
  border-radius:50%;
  height:32px;
  margin-left:20px;
  min-width:32px
}
.control-conditions__action_remove{
  border-radius:50%;
  border-style:solid;
  height:36px;
  min-width:36px;
  opacity:0;
  position:absolute;
  right:-18px;
  top:-18px
}

.data-tree-item {
	align-items: center;
	display: flex;
  cursor: pointer;
}

.data-tree-item__checkbox {
	background: #ffffff;
	line-height: 1;
	margin: 0 5px 0 1px
}

.data-tree-item__checkbox input {
	margin: 0
}

.data-tree-item__toggle {
	color: #637a87;
	display: inline-block;
	margin-left: 3px;
	margin-right: 3px;
	transition: transform .2s ease;
	visibility: hidden
}

.data-tree-item__toggle:hover,
.data-tree-item__toggle:focus {
	color: #133440
}

.data-tree-item__toggle_visible {
	cursor: pointer;
	visibility: visible
}

.data-tree-item__toggle_open {
	transform: rotate(90deg)
}

.data-tree-item__sample {
	color: #637a87;
	font-size: 12px;
	font-style: oblique;
	margin: 0 0 0 4px
}

.data-tree-item__icon {
	display: inline-block;
	height: 18px;
	text-align: center;
	vertical-align: middle;
	width: 18px;
  color: #637a87
}


.data-tree-item__pill {
	margin-left: 5px
}

.data-tree-item__label {
	font-weight: 400;
	margin: 0
}

.data-tree-item__label .data-tree-item__pill {
	cursor: pointer
}

.data-tree-pill {
	border: 1px solid transparent;
	display: inline-block;
	font-size:14px;
	line-height: inherit;
	padding: 4px 0
}

.data-tree-pill_draggable {
	background: #ffffff;
	border-color:#ced5db;
	color: #24434f;
	padding: 4px 8px
}