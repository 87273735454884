.component-node {
  background: transparent !important;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.component-node-body-container {
  position: relative;
}

.component-node-body {
  background: #ffffff;
  border: 0.5px solid #ebebeb;
  border-radius: 3px;
  padding: 12px;
  min-width: 250px;
  max-width: 250px;
  display: flex;
  z-index: 10;
  position: relative;
  align-items: center;
}

.hideBottomBorder {
  border-bottom: none;
}

.component-node-body-p2 {
  background: #ffffff;
  border: 0.5px solid #ebebeb;
  border-top: none;
  border-radius: 3px;
  padding: 5px 12px 12px;
  min-width: 250px;
  max-width: 250px;
  z-index: 10;
  position: relative;
  text-align: left;
}

.roleTextTitle{
  margin-bottom: 2px !important;
  color : '#282D30 !important';
  font-size: 12px !important
}

.roleFormControl{
  width:100%
}

.roleFormControl div[class*='MuiSelect-root']{
  font-size: 10px;
  padding: 5px 10px;
}

.layer-one {
  opacity: 0.5;
  background: #c1dbeb;
  top: -0.4em;
  left: 0;
  right: 0;
  width: 95%;
  border: solid 2px;
  height: 20px;
  margin: 0 auto;
  z-index: 9;
  position: absolute;
  border-radius: 3px;
}

.layer-two {
  opacity: 0.3;
  background: #c5d9e5;
  top: -0.7em;
  left: 0;
  right: 0;
  width: 90%;
  border: solid 1px;
  height: 20px;
  margin: 0 auto;
  z-index: 8;
  position: absolute;
  border-radius: 3px;
}
.start-node {
  min-width: 250px !important;
}

.workflow {
  border: 1px solid #49b8fa;
}

.workflow-component{
  border: 1px solid #49b8fa;
  border-bottom: none;
}

.component-node-body-workflow {
  background: #ffffff;
  border: 0.5px solid #49b8fa;
  border-top: none;
  padding: 5px 12px 12px;
  min-width: 250px;
  max-width: 250px;
  z-index: 10;
  position: relative;
  text-align: left;
}

.component-node-body > .component-settings{
  display: flex;
  position: absolute;
  right: 0;
  /* flex-direction: column; */
  /* float: left; */
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.component-node-body > .settingIcon {
  position: absolute;
  right: 0;
  top: 3px
}

.component-node-body > .componentDeleteIcon {
  position: absolute;
  right: 10px;
  top: 3px;
  display : none
}

.component-node-body > .hideDeleteIcon {
  display : none
}

.component-node-body:hover > .componentDeleteIcon {
  display: block;
}

.component-node-body > .img {
  border: 0.5px solid #EBEBEB;
  border-radius: 3px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  width: 24px;
  height: 24px;
}

.search-area {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.5px solid #ebebeb;
  border-radius: 3px;
  opacity: 1;
  padding: 12px 12px 0;
  width: 250px;
}

.search-bar {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.result-area {
  max-height: 85px;
  overflow-y: auto;
  padding: 5px 0;
  z-index: 10;
  margin-top: 2px;
}

/* .result-area > p {
  text-align: left;
  color: #333333;
  font-size: 7px;
  margin-top: 0;
} */


.result-area > p {
  text-align: left;
  letter-spacing: 0;
  margin: 0;
  padding: 0;
  padding-right: 30px;
  color: #333333;
  margin-top: 0;
  margin-bottom: 4px;
}

.search-input {
  height: 18px;
  padding: 0;
  font-size: 14;
}

.input-container {
  display: flex;
  flex:1;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cfd7df;
  border-radius: 4px;
  opacity: 1;
  padding: 2px 3px;
}

.input-container > input {
  font-size: 12px;
  border: 0;
  color: #9b9eac;
  opacity: 1;
}

input.workflowSearchInput ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-size: 8px;
  color: #9b9eac;
  opacity: 1; /* Firefox */
}

input.workflowSearchInput:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-size: 8px;
  color: #9b9eac;
}

input.workflowSearchInput::-ms-input-placeholder {
  /* Microsoft Edge */
  font-size: 8px;
  color: #9b9eac;
}

input.workflowSearchInput:focus {
  outline: none;
}

.component-node-body > .label {
  letter-spacing: 0;
  color: #333333;
  opacity: 1;
  font-weight: 600;
  font-size: 10px;
  width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.component-node-body > .labelProject {
  display: flex;
  align-items: center;
  letter-spacing: 0;
  color: #333333;
  opacity: 1;
  font-weight: 600;
  font-size: 10px;
  width: 130px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.line {
  width: 1px;
  height: 17px;
  background-color: #d8d8d8;
  margin-left: -1px;
}

.menu-area {
  display: flex;
  z-index: 20;
  align-items: center;
  color: #8d9093;
  font-size: 11px;
  justify-content: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  opacity: 1;
  cursor: pointer;
  padding: 0 2px 1px;
  margin-bottom: 5px;
}

.menu-area > .icon {
  padding: 4px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.menu-area > .icon:hover {
  background-color: #f2f2f2;
}

.user_wrk_avatar{
  margin-left: 20px;
}

.user_wrk_avatar .MuiAvatar-root{
  width: 20px;
  height: 20px;
}

.user_wrk_avatar [class*=MuiAvatar-root] {
  width: 20px;
  height: 20px;
  font-size: 10px;
}

.user_wrk_avatar [class*=MuiAvatarGroup-avatar] {
  margin: 0
}

.checkIconcustomimg{
  color: #2eb77d;
  width: 15px !important;
  margin-left: 5px;
}