
.color-options{
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  align-items: center;
  gap: 5px;
}
.clicked{
border: 1px solid lightgray;
border-bottom: none;
}
.option,.hexPreview{
width: 20px;
height: 20px;
border-radius: 4px;
background-color: #000000;
transition: 0.3s;
cursor: pointer;
}
.option:hover{
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid #403c3c;
  }
.color-picker form{
display: flex;
align-items: center;
column-gap: 5px;
width: 100%;
}
.color-picker input{
width: 65%;
height:1.3em;
border:1px solid lightgray;
border-radius: 5px;
padding-left:5px
}
.color-picker button{
margin:0;
padding:0;
cursor: pointer;
}
.color-picker input:focus{
outline: none;
}

.popover {
  position: absolute;
  z-index: 2;
}
.cover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
