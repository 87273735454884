.custom-decoration {
  background: red;
  color: #fff;
  font-size: 20px;
}

.custom-decoration::before {
  content: "Test";
  display: inline-block;
}

.widget {
  border: 1px solid #fff;
  border-radius: 8px;
  padding: 2px 8px;
  background-color: #334b6b;
  color: #fff;
}

.custom-codemirror {
  max-height: 150px; /* Set the height */
  border: 1px solid #ccc; /* Add a border */
}

.CodeMirror {
  font: inherit;
  height: 100%;
  min-height: 44px;
  padding: 0 4px;
}

.CodeMirror-wrap pre.CodeMirror-line {
  line-height: 30px;
}
/* 
.CodeMirror w-pill {
  margin-left: 2px;
  margin-right: 2px;
  position: relative;
  top: -1px;
}

.CodeMirror .CodeMirror-widget:first-child > w-pill,
.CodeMirror .CodeMirror-line > w-pill:first-child {
  margin-left: 0;
}

.CodeMirror-scroll {
  scrollbar-color: rgba(186, 196, 204, 0.4) transparent;
  scrollbar-width: thin;
  max-height: 514px;
}

.CodeMirror-scroll::-webkit-scrollbar {
  height: 12px;
  width: 12px;
}

.CodeMirror-scroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.CodeMirror-scroll::-webkit-scrollbar-corner {
  background-color: transparent;
}

.CodeMirror-scroll::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  background-color: #bac4cc66;
  border: 3px solid transparent;
  border-radius: 7px;
  box-shadow: inset 0 0 0 1px #00000005;
}

.CodeMirror-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #bac4cccc;
}

.CodeMirror-scroll::-webkit-scrollbar-thumb:vertical {
  min-height: 25px;
}

.CodeMirror-scroll::-webkit-scrollbar-thumb:horizontal {
  min-width: 25px;
}

.CodeMirror-scroll::-webkit-scrollbar-button {
  display: none;
  height: 0;
  width: 0;
}

.CodeMirror-vscrollbar,
.CodeMirror-hscrollbar {
  scrollbar-color: rgba(186, 196, 204, 0.4) transparent;
  scrollbar-width: thin;
}

.CodeMirror-vscrollbar::-webkit-scrollbar,
.CodeMirror-hscrollbar::-webkit-scrollbar {
  height: 12px;
  width: 12px;
}

.CodeMirror-vscrollbar::-webkit-scrollbar-track,
.CodeMirror-hscrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}

.CodeMirror-vscrollbar::-webkit-scrollbar-corner,
.CodeMirror-hscrollbar::-webkit-scrollbar-corner {
  background-color: transparent;
}

.CodeMirror-vscrollbar::-webkit-scrollbar-thumb,
.CodeMirror-hscrollbar::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  background-color: #bac4cc66;
  border: 3px solid transparent;
  border-radius: 7px;
  box-shadow: inset 0 0 0 1px #00000005;
}

.CodeMirror-vscrollbar::-webkit-scrollbar-thumb:hover,
.CodeMirror-hscrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #bac4cccc;
}

.CodeMirror-vscrollbar::-webkit-scrollbar-thumb:vertical,
.CodeMirror-hscrollbar::-webkit-scrollbar-thumb:vertical {
  min-height: 25px;
}

.CodeMirror-vscrollbar::-webkit-scrollbar-thumb:horizontal,
.CodeMirror-hscrollbar::-webkit-scrollbar-thumb:horizontal {
  min-width: 25px;
}

.CodeMirror-vscrollbar::-webkit-scrollbar-button,
.CodeMirror-hscrollbar::-webkit-scrollbar-button {
  display: none;
  height: 0;
  width: 0;
}

.CodeMirror-placeholder {
  color: var(--text-placeholder) !important;
}

.CodeMirror-dragcursors {
  display: none;
}

.CodeMirror-cursor {
  border-color: var(--text-primary);
}

.CodeMirror-lines {
  padding: 9px 0;
}

.CodeMirror-wrap .CodeMirror-line {
  word-break: break-all !important;
}

.CodeMirror-lint-tooltip {
  z-index: 1004;
}

.CodeMirror-fullscreen {
  height: auto;
  inset: 0;
  margin: 0 auto;
  position: absolute;
} */
